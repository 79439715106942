import { createRouter, createWebHistory } from '@ionic/vue-router';
//import { RouteRecordRaw } from 'vue-router';

const routes  = [
  {
    path: '',
    name: 'LoginPage',
    component: () => import ('../views/LoginPage.vue')
  },
  {
    path: '/',
    name: 'LoginPage',
    component: () => import ('../views/LoginPage.vue')
  },
  {
    path: '/home/:incheck',
    name: 'HomePage',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/location',
    name: 'LocationList',
    component: () => import ('../views/LocationPage.vue')
  },
  {
    path: '/types',
    name: 'TypeList',
    component: () => import ('../views/TypePage.vue')
  },
  {
    path: '/status',
    name: 'StatusList',
    component: () => import ('../views/StatusPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
